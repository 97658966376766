import { useEffect, useRef, useState } from "react";
import { Typed } from "react-typed";
import '../assets/css/index.less';

type TypedWithImageProps = {
    strings: { text: string; image: string }[];
    typeSpeed?: number;
    backSpeed?: number;
    loop?: boolean;
    cursorChar?: string;
  };
  
 const TypedWithImage: React.FC<TypedWithImageProps> = ({
    strings,
    typeSpeed = 200,
    backSpeed = 50,
    loop = true,
    cursorChar = '|'
  }) => {
    const el = useRef<HTMLDivElement>(null);
    const typed = useRef<Typed | null>(null);
    const [currentIndex,setCurrentIndex]=useState(0)
  
    useEffect(() => {
      if (el.current) {
        typed.current = new Typed(el.current, {
          strings: strings.map(item => item.text),
          typeSpeed,
          backSpeed,
          loop,
          showCursor: true,
          cursorChar,
          preStringTyped: (arrayPos: number) => {
            setCurrentIndex(arrayPos);
          }
        });
      }
  
      return () => {
        if (typed.current) {
          typed.current.destroy();
        }
      };
    }, [strings, typeSpeed, backSpeed, loop, cursorChar]);
  
    return (
      <div className="typed-container">
        <img src={strings[currentIndex]?.image} alt="" className="typed-image" />
        <div className="typed-output" ref={el}></div>
      </div>
    );
  };

export default TypedWithImage