import { useState } from 'react';
import '../../assets/css/header.less';
import img_logo from '../../assets/image/logo@2x.png';
import FadeInWhenVisible from '../../components/animation/FadeInWhenVisible';

export default function HeaderBlock() {
  const socialList= [
    {
      name: 'Twitter',
      icon: '#icon-svg-twitter',
      link: 'https://twitter.com/cetusprotocol'
    },
    {
      name: 'Discord',
      icon: '#icon-svg-Discord',
      link: 'https://discord.gg/cetusprotocol'
    },
    // {
    //   name: 'TG Group',
    //   icon: '#icon-svg-lark',
    //   link: 'https://t.me/cetuscommunity'
    // },
    {
      name: 'TG Channel',
      icon: '#icon-svg-tg',
      link: 'https://t.me/cetusprotocol'
    },
    {
      name: 'Medium',
      icon: '#icon-svg-medium',
      link: 'https://medium.com/@CetusProtocol'
    }
  ]
  const [isOpenH5,setIsOpenH5]= useState(false)
  return (
    <div className="header_container">
    <div className="center">
        <div className="logo">
        <a href="/">
          <img src={img_logo} alt="" />
        </a>
        </div>
        <div className="social">
        {socialList.map((item) => {
            return (
            <a className="item" key={item.link} href={item.link} target="_blank">
                <svg aria-hidden="true" className="icon">
                <use xlinkHref={item.icon}></use>
                </svg>
            </a>
            )})}
        </div>
        <div className="social-h5" onClick={()=>setIsOpenH5(!isOpenH5)}>
      <span>Social Media</span>
      <svg aria-hidden="true" className={isOpenH5 ? 'icon-hover icon_on' : 'icon_on'}>
        <use xlinkHref="#icon-icon_on" />
      </svg>
      {isOpenH5&&
      <FadeInWhenVisible>
        <div className="list-link">
            {socialList.map((item) => {
                return (
                <a className="item" key={item.link} href={item.link} target="_blank">
                    <svg aria-hidden="true" className="icon">
                    <use xlinkHref={item.icon}></use>
                    </svg>
                    { item.name }
                </a>
                )})}
        </div>
      </FadeInWhenVisible>
      }
    </div>
    </div>
    </div>
  );
}
