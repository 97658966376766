import './assets/css/terms.less';
import HeaderBlock from './components/content/HeaderBlock';
import FooterBlock from './components/content/FooterBlock';
function Terms() {
  return (
    <div className="home_container">
      <HeaderBlock />
        <div className="cetus-container">
          <div className="cetus">
            <p>CETUS PROTOCOL</p>
            <p className="bottom-box">Terms of Use Agreement</p>
            <span>Last Updated: [3 May 2023]</span>
            <div className="contailer-white-paper">
							Please do not access this Site (as defined below) where such access is prohibited by applicable law. Please
							carefully read these Terms of Use before using the Site. These Terms (as defined below) apply to any person or
							entity accessing the Site and by using the Site, you agree to be bound by them. The Terms contain a mandatory
							individual arbitration and className action/jury trial waiver provision that requires the use of arbitration on an
							individual basis to resolve disputes, rather than jury trials or className actions. If you do not want to be bound by
							these Terms, you should not access the Site. By using the Site in any capacity, you agree that you have read,
							understood, and agree to be bound by the Terms.
							<br />
							<br />
							<div className="title">1. Overview</div>
							<br />
							This Terms of Use Agreement (these "<span>Terms</span>") covers the www.cetus.zone website, mobile user-interfaces
							and all its
							affiliate websites (collectively, the "<span>Site</span>", including each of its subdomains and web or mobile
							applications)
							provided by <span>CETUS TECHNOLOGY LIMITED</span>, a company incorporated or to be incorporated in the British
							Virgin Islands
							(the "<span>Company</span>", "<span>we</span>" "<span>our</span>", or "<span>us</span>"), at times in conjunction
							with others. As part of the Site, we provide access
							to the decentralized peer-to-peer finance application (the "<span>Cetus protocol</span>"). The Cetus protocol is a
							decentralized exchange comprised of certain smart contracts deployed on the Sui and Aptos blockchain networks and
							related software development kits (collectively, the "<span>Smart Contracts</span>"), which allows traders or
							liquidity
							providers to trade Sui-based and Aptos-based tokens or other digital assets (collectively, "<span>Digital
							Assets</span>")
							directly in a peer-to-peer manner via Liquidity Pools (as defined herein). The Cetus protocol is made up of
							public, open-source or source-available software, including a set of Smart Contracts. Users can interact with the
							underlying Smart Contracts to create Liquidity Pools, view their Liquidity Pools created or accessed, and interact
							with other users in Cetus protocol ecosystem. In addition to the Site, you can access the Cetus protocol through a
							number of third-party web or mobile user-interfaces that we do not own, administer or control
							("<span>UIs</span>").
							<br />
							<br />
							These Terms apply to you ("<span>you</span>" or "<span>your</span>") as a user of our Site, including, without
							limitation, all the products,
							services, tools and information made available on the Site.
							<br />
							<br />
							You must be able to form a legally binding contract online either as an individual or on behalf of a legal entity.
							You represent that, if you are agreeing to these Terms on behalf of a legal entity, you have the legal authority
							to bind that entity to these Terms and you are not indirectly or directly included on any sanctions list and at
							least 18 years old or the age of majority where you reside, (whichever is older) can form a legally binding
							contract online, and have the full, right, power and authority to enter into and to comply with the obligations
							under these Terms.
							<br />
							<br />
							You are advised to periodically review these Terms so you understand any changes. We reserve the right, in our
							sole and absolute discretion, to make changes to our Terms. If we make changes, we will notify you by revising the
							"Last Updated" date at the beginning of these Terms accordingly. Changes are binding on users of the Site and will
							take effect immediately upon posting. As a user, you agree to be bound by any changes, variations, or
							modifications to our Terms and your continued use of the Site shall constitute acceptance of any such changes,
							revisions, variations, or modifications. By continuing to use the Site, you also acknowledge and agree that we
							have provided you with sufficient notice of such changes.
							<br />
							<br />
							<div className="title">2. Use of the Site</div>
							<br />
							To be eligible to use the Smart Contracts, the Site and the Services (as defined below), you must be of legal age
							to enter into these Terms where you live and have the legal capacity to enter into these Terms. The Smart
							Contracts, the Site and the Services is strictly NOT offered to persons or entities who reside in, are citizens
							of, are incorporated in, or have a registered office in any Restricted Territory, as defined below (any such
							person or entity from a Restricted Territory shall be a Restricted Person). If you are a Restricted Person, then
							do not attempt to access or use the Smart Contracts, the Site or the Services. Use of a virtual private network
							(e.g., a VPN) or other means by Restricted Persons to access or use the Smart Contracts, the Site or the Services
							is prohibited.
							<br />
							<br />
							For the purpose of these Terms, Restricted Territory includes but without limitation the United States of America,
							the People's Republic of China and other jurisdictions that restrict the access to or usage of cryptocurrencies or
							related services, or any state, country or region that is subject to sanctions enforced by the United States, the
							United Kingdom or the European Union.
							<br />
							<br />
							By using the Site, you expressly acknowledge and agree that:
							<br />
							<br />
							a. use of the Cetus protocol and/or Smart Contracts may require that you pay a fee, such as network fees and other
							charges needed to perform a transaction;
							<br />
							<br />
							b. your use of the Cetus protocol and/or Smart Contracts involves various risks, including, without limitation,
							risks of (a) losses while Digital Assets are being supplied to the Cetus protocol, (b) losses due to fluctuating
							prices of Digital Assets in trading pairs or liquidity pools and (c) losses due to bugs, errors, network outage,
							exploit incidents or any unforeseen circumstances;
							<br />
							<br />
							c. before using the Cetus protocol and/or Smart Contracts, you should review the relevant documentation to make
							sure you understand how the Cetus protocol and Smart Contracts work;
							<br />
							<br />
							d. you are responsible for doing your own diligence on the various UIs through which you access the Cetus protocol
							and/or Smart Contracts to understand the fees and risks they present;
							<br />
							<br />
							e. the Community has no control over any transactions conducted through the Cetus protocol and/or Smart Contracts,
							the method of payment of any transactions, or any actual payments of transactions, including, without limitation,
							use of any third-party services like Digital Asset wallet providers;
							<br />
							<br />
							f. you must ensure that you have a sufficient balance of the applicable Digital Assets stored at your compatible
							Digital Asset wallet address to complete any transaction on the Cetus protocol, Smart Contracts, or the Aptos
							network before initiating such transaction; and
							<br />
							<br />
							g. no individual or entity involved in creating the Cetus protocol or Smart Contracts will be liable for any
							claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of,
							the Cetus protocol or Smart Contracts, including, without limitation, any direct, indirect, incidental, special,
							exemplary, punitive or consequential damages, or loss of profits, Digital Assets, or anything else of value.
							<br />
							<br />
							The Smart Contract, the Site or the Services made available through the Site (or any portion thereof) may not be
							available to all users, and we reserve the right to assess or reassess at any time your eligibility to use all or
							part of the Smart Contract, the Site or the Services.
							<br />
							<br />
							The Smart Contract, the Site and the Services does not constitute, and may not be used for the purposes of, an
							offer or solicitation to anyone in any jurisdiction in which such offer or solicitation is not authorised, or to
							any person to whom it is unlawful to make such an offer or solicitation.
							<br />
							<br />
							Without limiting the foregoing, by using the Smart Contract, the Site or the Services, you acknowledge and
							understand that laws regarding digital assets, cryptocurrency derivatives, financial instruments, or investment
							products which may include digital assets, may vary from jurisdiction to jurisdiction, and it is your sole
							obligation to ensure that you fully comply with any law, regulation or directive, relevant to your jurisdiction
							with regard to the use of the Smart Contract, the Site or the Services. For the avoidance of doubt, the ability to
							access the Smart Contract, the Site or the Services does not necessarily mean that same (or your activities
							through it) are legal under the laws, regulations or directives relevant to your jurisdiction.
							<br />
							<br />
							<div className="title">3. The Site and transactions on the relevant Blockchain Network</div>
							<br />
							Transactions that take place via the visual user interface on the Site are confirmed via the relevant Blockchain
							Network. You understand that your public address on the relevant Blockchain Network will be made publicly visible
							whenever you engage in a transaction on the Site.
							<br />
							<br />
							Cetus protocol is a non-custodial protocol, therefore the Site does not hold or control your digital assets. Any
							digital assets which you may acquire through the usage of the Site will be held and administered solely by you
							through your selected electronic wallet, and we shall have no access to or responsibility in regard to such
							electronic wallet or digital asset held therein. It is solely your responsibility to select the wallet service
							provider to use in connection with the Site, and your use of such electronic wallet will be subject to the
							governing terms of use or privacy policy of the provider of such wallet. We neither own nor control your selected
							electronic wallet service, Google Chrome, any electronic wallet, the relevant Blockchain Network, or any other
							third party site, product, or service that you might access, visit, or use for the purpose of enabling you to use
							the various features of the Cetus protocol. We will not be liable for the acts or omissions of any such third
							parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other
							interaction with any such third parties.
							<br />
							<br />
							The Company will not create any hosted wallet for you or otherwise custody digital assets on your behalf, and it
							is your sole responsibility to maintain the security of your selected electronic wallet. In the event that you
							lose access to your electronic wallet, private key(s), password(s), or other method(s) of securing your wallet,
							all digital assets held in such wallet may be irretrievable, and the Company will be unable to assist you in any
							way. You hereby irrevocably waive, release and discharge all claims, whether known or unknown to you, against the
							Company, its affiliates and their respective shareholders, members, directors, officers, employees, agents and
							representatives related to your use of any wallet software, associated loss of digital assets, transaction
							failures, or any other defects that arise in the course of your use of your electronic wallet, including any
							losses that may obtain as a result of any failure of any Smart Contracts or the Site.
							<br />
							<br />
							The Company reserves the right to modify, suspend or discontinue, temporarily or permanently, all or any part of
							the Site with or without notice. You agree that the Company will not be liable to you or to any third party for
							any modification, suspension or discontinuance of all or any part of the Site.
							<br />
							<br />
							The publicly deployed Smart Contracts you interact with are experimental in nature and you should not utilise the
							Smart Contracts or Liquidity Pools for deployment of any substantial amount of digital assets.
							<br />
							<br />
							We reserve the right to disable access to the Site or the interface at any time in the event of any breach of the
							Terms, including without limitation, if we, in our sole discretion, believe that you, at any time, fail to satisfy
							the eligibility requirements set forth in the Terms. Further, we reserve the right to limit or restrict access to
							the Site by any person or entity, or within any geographic area or legal jurisdiction, at any time and at our sole
							discretion. We will not be liable to you for any losses or damages you may suffer as a result of or in connection
							with the Site being inaccessible to you at any time or for any reason.
							<br />
							<br />
							By interacting with Cetus protocol, users are agreeing to be bound by the governance process for the holders of
							the Cetus governance token (the "<span>Community</span>"), and enter into mutual covenants and/or obligations with
							other
							holders of Cetus governance tokens inter se. Without prejudice to the generality of the foregoing, the parameters
							for Liquidity Pools on Cetus protocol, composition of digital assets, reference/oracle pricing, as well as
							distribution of treasury fees or rewards to participants of the Community would be determined by said Community
							governance process. Users agree to accept the outcome of this governance process as legally binding and valid, and
							shall waive any rights to challenge these outcomes before any court of law, tribunal or regulatory authority.
							<br />
							<br />
							<div className="title">4. Services</div>
							<br />
							The Company has deployed the Smart Contracts on the relevant Blockchain Network for users to utilise in accordance
							with these Terms. Users may directly call the functions of the Smart Contracts directly, or access them via the
							user interface provided by the Site.
							<br />
							<br />
							Cetus protocol allows users to trade digital asset pairs directly in a peer-to-peer manner. Users will be able to
							act as market makers for these transactions by staking/pooling their digital assets into decentralised liquidity
							pools ("<span>Liquidity Pools</span>") to provide the necessary liquidity for transactions by other users. These
							digital assets
							may comprise various fungible cryptocurrencies in the market.
							<br />
							<br />
							Liquidity Pools and the underlying digital asset pairings will be selected and created by liquidity providers, who
							allow other users to access these Liquidity Pools to conduct trades. All trades conducted via Cetus protocol would
							be performed in a non-custodial manner and users remain in control of their digital assets.
							<br />
							<br />
							Further, Cetus protocol functions as an intermediary user interface connecting users with projects which intend to
							launch their own token sale projects via the Cetus protocol and Liquidity Pools. The Company does not actively
							introduce users to such projects or vice versa. Further, the Company does not act as a fiduciary or custodian in
							relation to any funds which you may place with a project, and any claim relating to these funds are solely against
							the relevant third party issuer for the project which bears sole responsibility for such funds.
							<br />
							<br />
							All interactions between liquidity providers and traders on Cetus protocol operate in a peer-to-peer manner.
							Liquidity providers and traders on Cetus protocol enter into a direct contractual relationship via the autonomous
							Smart Contracts and/or other smart contracts deployed by various other third party networks, and therefore
							liquidity providers wholly assume all responsibility towards the user of the Liquidity Pools. There is no further
							control by or interaction with the Company (or the relevant affiliate) which had deployed the Smart Contract(s).
							The Company and its affiliates shall in no circumstances be construed as a party to said peer-to-peer direct
							contractual relationship, is not liable for performance of obligations thereunder, nor does it bear any financial
							or commercial risk or provide any warranties or assurances in connection with the same.
							<br />
							<br />
							The Site merely provides a visual user interface allowing users to interact with Liquidity Pools and to access
							liquidity provided by liquidity providers, and does not act as an agent for any of the users. Although the Site is
							intended to display accurate and timely information regarding Liquidity Pools and possible swaps, the Site or
							relevant tools/information may not always be entirely accurate, complete or current and may also include technical
							inaccuracies or typographical errors. The pricing information data provided through the Site does not represent an
							offer, a solicitation of an offer, or any advice regarding, or recommendation to enter into, a transaction with
							the Company or the Site. Accordingly, users should verify all information before relying on it, and all decisions
							based on information contained on the Site or tools/information tools are at the sole responsibility of each user.
							Notwithstanding any of the other provisions in these Terms, any photographs, graphic illustrations, videos,
							models, charts, designs, or examples on the site are strictly for information purposes only and have no
							contractual value nor do they form the basis of any contract with the Company.
							<br />
							<br />
							Neither the Company nor the Site provides any digital asset exchange or portfolio/fund management services in
							connection with the Liquidity Pools. If you choose to engage in transactions with liquidity providers in Liquidity
							Pools or any other users, then such decisions and transactions and any consequences flowing therefrom are your
							sole responsibility. In no event shall the Company, its affiliates or their respective directors or employees be
							responsible or liable to you or anyone else, directly or indirectly, for any damage or loss arising from or
							relating to any interaction or continued interaction with Liquidity Pools, or reliance on any information provided
							on the Site (including, without limitation, directly or indirectly resulting from errors in, omissions of or
							alterations to any such information).
							<br />
							<br />
							<span>
							THE SITE SOLELY FUNCTIONS AS A VISUAL USER INTERFACE. IN NO CIRCUMSTANCES SHALL THE COMPANY, THE SMART
							CONTRACTS,
							OR THE SITE BE CONSTRUED AS A DIGITAL ASSET EXCHANGE, BROKER, DEALER, FUND MANAGER, FINANCIAL INSTITUTION,
							EXCHANGE, CUSTODIAN, ROBO-ADVISOR, INTERMEDIARY, OR CREDITOR. THE SITE DOES FACILITATE OR ARRANGE TRANSACTIONS
							BETWEEN BUYERS AND SELLERS, INCLUDING WITH RESPECT TO ANY TRANSACTIONS THAT OCCUR IN CONNECTION WITH A LIQUIDITY
							POOL, WHICH TRANSACTIONS OCCUR ON THE RELEVANT BLOCKCHAIN NETWORK. THE COMPANY IS NOT A COUNTERPARTY TO ANY
							TRANSACTION FACILITATED BY THE SMART CONTRACTS OR THE SITE OR FOR ANY USER OF THE SITE. NEITHER THE SMART
							CONTRACTS OR THE SITE PROVIDES FINANCIAL ADVISORY, LEGAL, REGULATORY, OR TAX SERVICES DIRECTLY, INDIRECTLY,
							IMPLICITLY, OR IN ANY OTHER MANNER, AND YOU SHOULD NOT CONSIDER ANY CONTENT CONTAINED IN THESE TERMS OR
							OTHERWISE
							POSTED ON THE SITE TO BE A SUBSTITUTE FOR PROFESSIONAL FINANCIAL, LEGAL, REGULATORY, TAX OR OTHER ADVICE. THE
							COMPANY DOES NOT SUPPORT OR ENDORSE ANY LIQUIDITY POOL CREATED BY ANY USER OF CETUS PROTOCOL, AND EACH SUCH
							CREATOR IS AN INDEPENDENT AGENT WITH NO EMPLOYMENT OR OTHER CONTRACTUAL RELATIONSHIP WITH THE COMPANY.</span>
							<br />
							<br />
							The Company reserves the right to suspend or terminate access to the Site or the Smart Contracts by any creator of
							Liquidity Pools or user of Liquidity Pools for any reason whatsoever (including without limitation for a breach of
							these Terms). You agree that the Company will not be liable to you or to any third party for any suspension or
							termination of any user.
							<br />
							<br />
							Access to the Smart Contracts or the Site may become degraded or unavailable during times of significant
							volatility or volume. This could result in the inability to interact with third-party services for periods of time
							and may also lead to support response time delays. The Company cannot guarantee that the Smart Contracts or the
							Site will be available without interruption and neither do we guarantee that requests to interact with third-party
							services will be successful.
							<br />
							<br />
							<div className="title">5. Fees and Payment</div>
							<br />
							If you elect to interact with Liquidity Pools, all transactions will be conducted solely through the relevant
							Blockchain Network. We will have no insight into or control over these payments or transactions, nor do we have
							the ability to reverse any transactions. With that in mind, we will have no liability to you or to any third party
							for any claims or damages that may arise as a result of any transactions that you engage in via the Site, or using
							the Smart Contracts, or any other transactions that you conduct via the relevant Blockchain Network.
							<br />
							<br />
							The relevant Blockchain Network typically requires the payment of a transaction fee (a "<span>Gas Fee</span>") for
							every
							transaction that occurs on the relevant Blockchain Network. The Gas Fee funds the network of computers that run
							the decentralised network. This means that you will need to pay a Gas Fee for each transaction that occurs via the
							Smart Contracts.
							<br />
							<br />
							You may be subject to certain additional fees and commissions, including fees imposed by creators of Liquidity
							Pools for accessing and utilising the Liquidity Pools as notified to you prior to engaging with any digital asset
							swap or liquidity provision. The Company also reserves the right to levy additional fees for access via the Smart
							Contracts or the Site in the future. You agree to promptly pay all aforementioned fees and commissions.
							<br />
							<br />
							Notwithstanding anything in these Terms to the contrary, you will be solely responsible to pay any and all sales,
							use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter
							claimed or imposed by any governmental authority (collectively, "<span>Taxes</span>") associated with your use of
							the Site or
							the Smart Contracts (including, without limitation, any Taxes that may become payable as the result of your
							ownership or transfer of digital assets or interaction with any Liquidity Pool, or relating to Cetus protocol).
							<br />
							<br />
							<div className="title">6. Access / Disclaimer of Warranties</div>
							<br />
							ACCESS TO THIS SITE AND THE PRODUCTS HEREIN ARE PROVIDED ON AN 'AS IS' AND 'AS AVAILABLE' BASIS WITHOUT WARRANTIES
							OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
							FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NO WARRANTY IS PROVIDED THAT THE SITE OR ANY PRODUCT WILL
							BE FREE FROM DEFECTS OR VIRUSES OR THAT OPERATION OF THE PRODUCT WILL BE UNINTERRUPTED. YOUR USE OF THE SITE AND
							ANY PRODUCT AND ANY MATERIAL OR SERVICES OBTAINED OR ACCESSED VIA THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND
							YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE RESULTING FROM THEIR USE.
							<br />
							<br />
							We do not guarantee or promise that the Site, or any content on it, will always be available, functional, usable
							or uninterrupted. From time to time, access may be interrupted, suspended or restricted, including because of a
							fault, error or unforeseen circumstances or because we are carrying out planned maintenance or changes.
							<br />
							<br />
							We reserve the right to limit the availability of the site to any person, geographic area or jurisdiction in our
							sole discretion and/or to terminate your access to and use of the site, at any time and in our sole discretion. We
							may suspend or disable your access to the Site for any reason and in our sole discretion, including for any
							intentional or unintentional breaches of these Terms. We may remove or amend the content of the Site at any time.
							Some of the content may be out of date at any given time and we are under no obligation to update or revise it. We
							do not promise or guarantee that the Site, or any content on it, will be free from errors or omissions.
							<br />
							<br />
							We will not be liable to you for any issue, loss or damage you may or have suffered as a result of the Site being
							unavailable at any time for any reason. You will comply with all applicable domestic and international laws,
							statutes, ordinances, rules and regulations applicable to your use of the site ("<span>Applicable Laws</span>").
							<br />
							<br />
							As a condition to accessing or using the Site, you agree and represent that you will:
							<br />
							<br />
							<ul>
							<li>Only use the Services and the Site for lawful purposes and in adherence with these Terms;</li>
							<li>Ensure that all information that you provide on the Site is current, complete, and accurate; and</li>
							<li>Maintain the security, privacy and confidentiality of access to your Digital Asset wallet address.</li>
							</ul>
							<br />
							As a condition to accessing or using the Site or the Services, you will not:
							<br />
							<br />
							<ul>
							<li>Violate any Applicable Law, including, without limitation, any relevant and applicable anti-money laundering
									and
									anti-terrorist financing and sanctions laws and any relevant and applicable privacy, secrecy and data
									protection
									laws.</li>
							<li>Use the Site for any purpose or conduct that is directly or indirectly unlawful;</li>
							<li>Export, reexport, or transfer, directly or indirectly, any technology belonging to us or the Cetus protocol
									in
									violation of applicable export laws or regulations;</li>
							<li>Infringe on or misappropriate any contract, intellectual property or other third-party right, or commit a
									tort
									while using the Site;</li>
							<li>Misrepresent, with omission or otherwise, the truthfulness, sourcing or reliability of any content on the
									Site;</li>
							<li>Use the Site in any manner that could interfere with, disrupt, negatively affect, redirect or inhibit other
									users from fully enjoying the Site or the Cetus protocol, or that could damage, disable, overburden, or impair
									the
									functioning of the Site or the Cetus protocol in any manner;</li>
							<li>Attempt to circumvent or disable any content filtering techniques or security measures that Cetus employs on
									the
									Site, or attempt to access any service or area of the Site that you are not authorized to access;</li>
							<li>Introduce or use any malware, virus, Trojan horse, worm, logic bomb, drop-dead device, backdoor, shutdown
									mechanism or other harmful material into the Site;</li>
							<li>Post content or communications on the Site that are, in our sole discretion, libelous, defamatory, profane,
									obscene, pornographic, sexually explicit, indecent, lewd, vulgar, suggestive, harassing, hateful, threatening,
									offensive, discriminatory, bigoted, abusive, inflammatory, fraudulent, deceptive or otherwise objectionable;
							</li>
							<li>To the extent applicable, post content on the Site containing unsolicited promotions, commercial messages or
									any
									chain messages or user content designed to deceive, induce or trick the user of the Site; or</li>
							<li>Encourage or induce any third party to engage in any of the activities prohibited under these Terms.</li>
							</ul>
							<br />
							You acknowledge that the Site and your use of the Site present certain risks, including without limitation the
							following risks:
							<br />
							<br />
							<ul>
							<li>Losses while digital assets are being supplied to the Cetus protocol and losses due to the fluctuation of
									prices
									of tokens in a trading pair or liquidity pool. Prices of digital currencies, tokens and/or other digital
									assets
									fluctuate day by day or even minute by minute. The value of your available balance could surge or drop
									suddenly.
									Please note that there is a possibility that the price of tokens could decrease to zero. Prices of tokens are
									prone to significant fluctuations, for example, due to announced proposed legislative acts, governmental
									restrictions, news related to cyber crimes or other factors causing potentially excessive market enthusiasm,
									disproportionate loss in confidence, or manipulation by others in the market.</li>
							<li>Risks associated with accessing the Cetus protocol through third party UIs. You are responsible for doing
									your
									own diligence on those UIs to understand and accept the risks that use entails. You are also responsible for
									doing
									your own diligence on those interfaces to understand and accept any fees that those interfaces may charge.
							</li>
							<li>You represent that you have sufficient knowledge, market sophistication, professional advice and experience
									to
									make your own evaluation of the merits and risks of any interaction with Liquidity Pools and the underlying
									digital assets. You accept all consequences of participating in such interactions, including the risk that you
									may
									lose access to your digital assets indefinitely. All decisions to interact with Liquidity Pools are made
									solely by
									you. Notwithstanding anything in these Terms, the Company accepts no responsibility whatsoever for and will in
									no
									circumstances be liable to you in connection with any interaction with Liquidity Pools and the underlying
									digital
									assets. Under no circumstances will the operation of all or any portion of the Smart Contracts or the Site be
									deemed to create a relationship that includes any management of any assets, or the provision or tendering of
									investment advice.</li>
							<li>Risks associated with any Smart Contracts with which you interact.</li>
							<li>No creator of Liquidity Pools will be able to guarantee the future performance of digital assets held in a
									Liquidity Pool, any specific level of performance, the success of any strategy or your overall results from
									interacting with Liquidity Pools. When reviewing the information, portfolio, performance, opinions of these
									creators, do not assume that such party is unbiased, independent or qualified to provide financial information
									or
									opinions. Past performance and risk scores have many inherent limitations and are not indicative of future
									results. No representation or guarantee is being made that any creator of Liquidity Pools will or is likely to
									achieve gains or losses similar to the past performance. The actual percentage gains or losses experienced by
									users will vary depending on many factors.</li>
							<li>Although we do not have access to your assets, you are reminded and acknowledge that at any time, your
									access to
									your Digital Assets through third-party Digital Asset wallet services, unrelated to the Site, may be suspended
									or
									terminated or there may be a delay in your access or use of your Digital Assets, which may result in the
									Digital
									Assets diminishing in value or you being unable to complete a Smart Contract.</li>
							<li>You are reminded of the inherent risks with digital assets and decentralized finance including the fact that
									tokens are not legal tender and are not backed by any government. Unlike fiat currencies, which are regulated
									and
									backed by local governments and central banks, tokens are based only on technology and user consensus, which
									means
									that in cases of manipulations or market panic, central governments will not take any corrective actions or
									measures to achieve stability, maintain liquidity or protect their value. There is a possibility that certain
									transactions cannot be settled or may be difficult to settle, or can be completed only at significantly
									adverse
									prices depending on the market situation and/or market volume. Transactions may be irreversible, and,
									accordingly,
									potential losses due to fraudulent or accidental transactions are not recoverable. Some blockchain
									transactions
									are deemed to be completed when recorded on a public ledger, which is not necessarily the date or time when
									you or
									another party initiated the transaction.</li>
							<li>The regulatory frameworks applicable to blockchain transactions in connection with tokens are still
									developing
									and evolving. It is possible that your transactions or funds are, or may be in the future, subject to various
									reporting, tax or other liabilities and obligations. Legislative and regulatory changes or actions at the
									country
									or international level may materially and adversely affect the use, transfer, exchange, and value of your
									tokens.</li>
							<li>All smart contracts (including the Smart Contracts) may contain security vulnerabilities, errors, failures,
									bugs
									or economic loopholes which may be exploited by third parties, causing you to suffer losses in connection with
									any
									digital assets re-deployed by Liquidity Pools. Interaction with these Smart Contracts are entirely at your own
									responsibility and liability, and the Company is not a party to the Smart Contracts. The Company cannot be
									responsibility for any security vulnerabilities, errors, failures, bugs or economic loopholes in respect of
									these
									third party protocols or the underlying smart contracts.</li>
							<li>The Site and/or Cetus protocol may be entirely or partially suspended or terminated for any or no reason,
									which
									may limit your access to your Digital Assets.</li>
							<li>You are solely responsible for understanding and complying with any and all Applicable Laws in connection
									with
									your acceptance of these Terms and your use of any part of the Site, including but not limited to those
									related to
									taxes as well as reporting and disclosure obligations.</li>
							<li>This list of risk factors is non-exhaustive, and other risks, arising either now or in the future, could
									additionally be relevant and applicable to you in making an informed judgment to accept, or continue to
									accept,
									these Terms and/or use, or continue to use the Site.</li>
							</ul>
							<br />
							Accordingly, you expressly acknowledge and agree that:
							<br />
							<br />
							a. you assume all risk in connection with the specific risks identified above;
							<br />
							<br />
							b. you assume all risk in connection with your access to and use of the Site, the Cetus protocol and the Smart
							Contracts;
							<br />
							<br />
							c. that you expressly waive and release us, the Cetus protocol and the Community from any and all liability,
							claims, causes of action, responsibility or damages arising from or in any way related to your use of the Site,
							the Cetus protocol or the Smart Contracts;
							<br />
							<br />
							d. upgrades and modifications to the Cetus protocol are managed in a community-driven way by holders of the Cetus
							protocol governance token; and
							<br />
							<br />
							e. no individual or entity involved in creating the Cetus protocol will be liable for any claims or damages
							whatsoever associated with your use, inability to use, or your interaction with other users of, the Cetus
							protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or
							loss of profits, Digital Assets, cryptocurrencies, tokens, or anything else of value.
							<br />
							<br />
							All information provided by or on behalf of the Company is for informational purposes only and should not be
							construed as legal, tax or financial advice. In particular, any summaries or reports available on the Site are
							provided to users for informational purposes only, and users should refrain from taking any action in reliance on
							any information contained in these Terms or provided by or on behalf of the Company. You acknowledge, agree, and
							understand that you are solely responsible for assessing whether to participate in any project. Before you decide
							to make any financial, legal, or other decisions relating to any project, you shall conduct all appropriate due
							diligence and seek all appropriate legal, tax or financial advice from independent professionals in connection
							with the same. You acknowledge and agree that the Company does not, in any way, supervise, direct, control, or
							evaluate any project and accordingly cannot be responsible for any project.
							<br />
							<br />
							Further, you accept and confirm that a conflict of interest may arise when the interest of the Company and its
							affiliates competes or may appear to compete with your interests under these Terms. Specifically, you hereby
							acknowledge and confirm that: (a) the Company may participate in certain transactions in an individual capacity;
							(b) the Company may execute at the same time its own orders which may be opposite that of a user; (c) the Company
							may establish business, including without limitation, trading relationships with other ecosystem players,
							financial institutions, digital asset owners, digital asset exchanges, issuers of financial instruments and we may
							have a financial interest in such instruments; (d) the Company may seek to act as a market maker and in this
							context there may be inherent conflicts of interest; and (e) the Company may compensate or share its revenues from
							activities in connection with the Smart Contracts, the Site or Liquidity Pools with various users, creators of
							Liquidity Pools, users of Liquidity Pools, the Company's affiliates, partners or other similar parties.
							<br />
							<br />
							<div className="title">7. Third-Party Content</div>
							<br />
							The Site may contain hyperlinks or references to third-party websites or content. Any such hyperlinks or
							references are provided for your information and convenience only. We have no control over third-party websites
							and accept no legal responsibility for any content, material or information contained in them. The display of any
							hyperlink and reference to any third-party website does not mean that we endorse that third party's website,
							products or services or opine on the accuracy or reliability of such information. Your use of a third-party site
							may be governed by the terms and conditions of that third-party site.
							<br />
							<br />
							<div className="title">8. Intellectual Property Rights</div>
							<br />
							We are the owner of all intellectual property rights in the Site and the material published on them. To the extent
							practical, these works are protected by copyright laws and all such rights are reserved. www.cetus.zone is our
							uniform resource locator (‘URL’). You will not make use of this URL (or any other URL owned by us) on another
							website or digital platform without our prior written consent. Any unauthorized use or reproduction may be
							prosecuted. You will retain ownership of all copyright in data you upload or submit by, through or to the Site.
							You grant us a worldwide, royalty-free, irrevocable license to use, copy, distribute or publish and send this data
							in any manner.
							<br />
							<br />
							<div className="title">9. Copyright Infringement</div>
							<br />
							We take claims of copyright infringement seriously. If you believe any materials accessible on or through the Site
							or any copyrighted materials posted to the Site by another user infringe your copyright, you may request removal
							of those materials (or access to them) by sending written notification to hello@cetus.zone.
							<br />
							<br />
							Please be aware that if you knowingly materially misrepresent that material or activity on the Site is infringing
							your copyright, you may be held liable for damages (including costs and attorneys’ fees) under Singapore’s
							Copyright Act 2021.
							<br />
							<br />
							<div className="title">10. Disclaimers</div>
							<br />
							YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SMART CONTRACTS, THE SITE AND LIQUIDITY
							POOLS IS AT YOUR SOLE RISK, AND THAT THE SAME ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY
							KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE COMPANY, ITS
							SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES
							REGARDING THE SMART CONTRACTS, THE SITE AND LIQUIDITY POOLS AND ANY PART OF IT, INCLUDING ANY IMPLIED WARRANTIES
							OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY.
							WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE COMPANY, ITS SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT
							REPRESENT OR WARRANT TO YOU THAT: (A) YOUR ACCESS TO OR USE OF THE SMART CONTRACTS, THE SITE AND LIQUIDITY POOLS
							WILL MEET YOUR REQUIREMENTS, (B) YOUR ACCESS TO OR USE OF THE SMART CONTRACTS, THE SITE AND LIQUIDITY POOLS WILL
							BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (C) USAGE DATA PROVIDED THROUGH THE SMART CONTRACTS, THE SITE
							AND LIQUIDITY POOLS WILL BE ACCURATE, (D) THE SMART CONTRACTS, THE SITE AND LIQUIDITY POOLS, OR ANY CONTENT,
							SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SMART CONTRACTS, THE SITE AND LIQUIDITY POOLS ARE FREE OF
							VIRUSES OR OTHER HARMFUL COMPONENTS, OR (E) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SMART CONTRACTS, THE
							SITE AND LIQUIDITY POOLS WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
							CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
							<br />
							<br />
							YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE
							THAT THE COMPANY HAS NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO THE COMPANY'S
							WILFUL DEFAULT.
							<br />
							<br />
							DIGITAL ASSETS ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE
							RELEVANT BLOCKCHAIN NETWORK. ALL SMART CONTRACTS IN CONNECTION WITH CETUS PROTOCOL ECOSYSTEM ARE DEPLOYED ON AND
							INTERACTIONS/TRANSACTIONS WITH THE SAME OCCUR ON THE DECENTRALISED LEDGER WITHIN THE RELEVANT BLOCKCHAIN NETWORK.
							WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS.
							<br />
							<br />
							THE COMPANY IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE RELEVANT BLOCKCHAIN
							NETWORK OR YOUR SELECTED ELECTRONIC WALLET SERVICE, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR
							REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE RELEVANT BLOCKCHAIN
							NETWORK, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
							<br />
							<br />
							<div className="title">11. Limitation of liability</div>
							<br />
							YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY
							THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR IN
							CONNECTION WITH THE SMART CONTRACTS, THE SITE OR LIQUIDITY POOLS, HOWSOEVER CAUSED AND UNDER ANY THEORY OF
							LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF
							GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER
							INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
							<br />
							<br />
							YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE
							TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE SMART CONTRACTS, THE
							SITE OR LIQUIDITY POOLS, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE
							LOWER OF (A) THE FEE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE
							CLAIM AROSE, OR (B) US$200.
							<br />
							<br />
							YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SMART CONTRACTS, THE SITE AND LIQUIDITY POOLS AVAILABLE TO YOU AND
							ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN,
							WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE
							BARGAIN BETWEEN US. WE WOULD NOT BE ABLE TO PROVIDE THE SMART CONTRACTS, THE SITE OR LIQUIDITY POOLS TO YOU
							WITHOUT THESE LIMITATIONS.
							<br />
							<br />
							SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND SOME
							JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY FROM CONSUMER PRODUCTS, SO
							THE ABOVE LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS.
							<br />
							<br />
							<div className="title">12. Indemnification</div>
							<br />
							You agree to indemnify and hold us and all of our officers, directors, council members, employees, contractors,
							agents, affiliates, and subsidiaries harmless from any claim or demand, including attorneys’ fees and costs, made
							by any third-party due to or arising out of (a) your use of the Site, the Cetus protocol, or the Smart Contracts
							or (b) these Terms.
							<br />
							<br />
							<div className="title">13. General</div>
							<br />
							We may perform any of our obligations, and exercise any of the rights granted to us under these Terms, through an
							affiliated or unaffiliated third-party. We may assign any or all our rights and obligations under these Terms to
							any third-party.
							<br />
							<br />
							If any clause or part of any clause of these Terms is found to be void, unenforceable or invalid, then it will be
							severed from these Terms, leaving the remainder in full force and effect, provided that the severance has not
							altered the basic nature of these Terms.
							<br />
							<br />
							No single or partial exercise, or failure or delay in exercising any right, power or remedy by us shall constitute
							a waiver by us of, or impair or preclude any further exercise of, that or any right, power or remedy arising under
							these terms and conditions or otherwise. If any of the provisions in these Terms are found to be illegal, invalid
							or unenforceable by any court of competent jurisdiction, the remainder shall continue in full force and effect.
							<br />
							<br />
							All disclaimers, indemnities and exclusions in these Terms shall survive termination of the Terms and shall
							continue to apply during any suspension or any period during which the Site is not available for you to use for
							any reason whatsoever.
							<br />
							<br />
							These Terms and the documents referred to in them set out the entire agreement between you and us with respect to
							your use of the Site, the Cetus protocol, the Smart Contracts and the services provided via the Site and supersede
							any and all prior or contemporaneous representations, communications or agreements (written or oral) made between
							you or us.
							<br />
							<br />
							There are no third party beneficiaries to these Terms. A person who is not a party under these Terms has no right
							under any applicable law to enforce or to enjoy the benefit of these Terms.
							<br />
							<br />
							Nothing in these Terms create any agency, partnership, joint venture or any similar relationship between the
							Company and you, nor cause the Company and you to be deemed acting in concert in any respect.
							<br />
							<br />
							These Terms shall be governed by the laws of the British Virgin Islands. Any dispute, controversy, or claim
							arising out of or in relation to these Terms, including the validity, invalidity, breach or termination thereof,
							shall be settled by arbitration in accordance with the Singapore Arbitration Act (2001), as may be amended from
							time to time, which is in force on the date when the Notice of Arbitration is submitted in accordance with these
							Rules. The number of arbitrators shall be one or three; the seat of the arbitration shall be determined by the
							arbitrator(s); the arbitral proceedings shall be conducted in English. The applicable law shall be Singapore law
							or another choice of law determined in our sole discretion.
							<br />
							<br />
							With respect to all persons and entities, regardless of whether they have obtained or used the site for personal,
							commercial or other purposes, all disputes, controversies or claims must be brought in the parties’ individual
							capacity, and not as a plaintiff or className member in any purported className action, collective action or other
							representative proceeding. This waiver applies to className arbitration, and, unless we agree otherwise, the
							arbitrator may not consolidate more than one person’s claims. You agree that, by entering into these Terms, you
							and us are each waiving the right to a trial by jury or to participate in a className action, collective action, or
							other representative proceeding of any kind.
							<br />
							<br />
							<div className="title">14. Force Majeure</div>
							<br />
							There is a risk that transactions effected through the Site may be affected by system failures resulting from
							adverse events, natural disasters, pandemics and other emergencies, as well as unforeseen significant changes in
							the external environment. With regard to opportunity loss (e.g., loss of opportunity to place a payment
							instruction, resulting in loss of profits which could have been obtained) due to occurrences such as emergency
							situations and force majeure events, we are under no obligation to take any corrective action or measure and shall
							no under circumstances be liable for any lost profits or other trading losses.
							<br />
							<br />
							<div className="title">15. Consent to Electronic Disclosures and Signatures</div>
							<br />
							Because the Company operates only on the Internet, it is necessary for you to consent to transact business with us
							online and electronically. As part of doing business with us, therefore, we also need you to consent to our
							providing you certain disclosures electronically via the Site. By agreeing to these Terms, you agree to receive
							electronically all documents, communications, notices, contracts, and agreements arising from or relating to your
							use of the Site and Services.
							<br />
							<br />
							By accepting these Terms or contacting us in any manner, you expressly consent to be contacted by us, our agents,
							representatives, affiliates, or anyone calling on our behalf for any and all purposes, in any way, including SMS
							messages (including text messages), calls using pre-recorded messages or artificial voice, and calls and messages
							delivered using an automatic telephone dialling system or an automatic texting system. Notwithstanding the
							aforementioned, any form of communication from the Company will be provided to you electronically through the Site
							or (if applicable) via email to the email address provided. If you require paper copies of any agreements or
							disclosures, you may print such documents desired.
							<br />
							<br />
							Your consent to receive disclosures and transact business electronically, and our agreement to do so, applies to
							any transactions to which such disclosures relate, whether between you and the Company or a third party by and
							through the Services. Your consent will remain in effect for so long as you are a user and, if you are no longer a
							user, will continue until such a time as all disclosures relevant to Services received through the Site.
            </div>
          </div>
        </div>
      <FooterBlock />
    </div>
  );
}

export default Terms;
