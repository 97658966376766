import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const FadeInWhenVisible = ({ children,delay=0 }:any) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false, // 每次元素进入视口都会触发动画
    threshold: 0.1 // 定义元素可见的比例，0.1 表示10%元素可见时触发
  });

  React.useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y:80 });
    }
  }, [controls, inView]);

  const [exitRef, exitInView] = useInView({
    triggerOnce: false,
    threshold: 0.3, // 90% 可见时触发（即仅剩10%离开时）
  });

  React.useEffect(() => {
    if (!exitInView) {
      controls.start({ opacity: 0 });
    } else {
      controls.start({ opacity: 1 });
    }
  }, [controls, exitInView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 80 }}
      animate={controls}
      transition={{ duration:0.5,delay }}
    >
      <motion.div
        ref={exitRef}
        initial={{ opacity: 1 }}
        animate={{ opacity: exitInView ? 1 : 0 }}
        transition={{ duration:0.5 }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default FadeInWhenVisible;