import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/animation.css';
import App from './App';
import Terms from './Terms';
import Disclaimer from './Disclaimer';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <Router>
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/disclaimer" element={<Disclaimer />} />
  </Routes>
</Router>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
