import { useEffect, useRef, useState } from 'react';
import './assets/css/index.less';
import FadeInUpWhenVisible from './components/animation/FadeInUpWhenVisible'
import FadeInWhenVisible from './components/animation/FadeInWhenVisible';
import FadScaleWhenHover from './components/animation/FadScaleWhenHover';
import HeaderBlock from './components/content/HeaderBlock';
import FooterBlock from './components/content/FooterBlock';
import img_OKX from './assets/image/img_okx@2x.png';
import img_KUCOIN from './assets/image/img_KUCOIN@2x.png';
import img_sui from './assets/image/img_sui@2x.png';
import img_Comma3 from './assets/image/img_Comma3@2x.png';
import img_NGC from './assets/image/img_NGC@2x.png';
import img_animca from './assets/image/img_animca@2x.png';
import img_IDG from './assets/image/img_IDG@2x.png';
import img_Leland from './assets/image/img_Leland@2x.png';
import img_AC from './assets/image/img_AC@2x.png';
import img_Coin98 from './assets/image/img_Coin98@2x.png';
import img_Gate from './assets/image/img_Gate@2x.png';
import img_cetusaquarium_left from './assets/image/img_cetusaquarium_left@2x.png';
import icon_sui from './assets/image/SUI@2x.png';
import icon_sui_new from './assets/image/icon-sui-new.png';
import icon_sui_bg from './assets/image/img-Sui-new.png';
import icon_aptos_bg from './assets/image/img-Aptos.png';
import icon_Aptos from './assets/image/icon-Aptos.png';
import full_logo from './assets/image/full-logo@2x.png';
import img_OKX_h5 from './assets/image/img_OKX_h5@2x.png';
import img_KUCOIN_h5 from './assets/image/img_KUCOIN_h5@2x.png';
import img_SUI_h5 from './assets/image/img_SUI_h5@2x.png';
import img_Comma_h5 from './assets/image/img_Comma_h5@2x.png';
import img_NGC_h5 from './assets/image/img_NGC_h5@2x.png';
import img_animca_h5 from './assets/image/img_animca_h5@2x.png';
import img_IDG_h5 from './assets/image/img_IDG_h5@2x.png';
import img_Leland_h5 from './assets/image/img_Leland_h5@2x.png';
import img_AC_h5 from './assets/image/img_AC_h5@2x.png';
import img_Coin98_h5 from './assets/image/img_Coin98_h5@2x.png';
import img_Gate_h5 from './assets/image/img_Gate_h5@2x.png';
import TypedWithImage from './components/TypedWithImage';


function App() {
  const  [faqVal,setFaqVal]=useState('')
  const stringsWithImages = [
    { text: 'Aptos', image: icon_Aptos },
    { text: 'Sui', image: icon_sui_new },
  ]
  const faqForm = [
    {
      value: '1. How to describe Cetus?',
      child:
        'Cetus is a pioneer DEX and concentrated liquidity protocol built on the Sui and Aptos blockchain. The mission of Cetus is building a powerful and flexible underlying liquidity network to make trading easier for any users and assets. It focuses on delivering the best trading experience and superior liquidity efficiency to DeFi users through the process of building its concentrated liquidity protocol and a series of affiliate interoperable functional modules.'
    },
    {
      value: '2. What is concentrated liquidity?',
      child:
        'Liquidity in a common AMM is distributed uniformly along the entire price curve (0, ∞). In comparison, concentrated liquidity is the liquidity allocated within a specified price range. A concentrated liquidity protocol like Cetus allows liquidity providers to contribute their liquidity within their custom price ranges.'
    },
    {
      value: '3. What is Move and what strengths does it have?',
      child:
        'Move is a new Rust-based open source programming language for writing safe smart contracts. It was originally developed at Facebook to power the Diem blockchain. However, Move was designed as a platform-agnostic language to enable common libraries, tooling, and developer communities across blockchains with vastly different data and execution models. The Move language is designed with an emphasis on security and is designed to avoid many classic Web3 vulnerabilities , including but not limited to re-entrance vulnerabilities, poison tokens, and spoofed token approvals, etc. Its Move Prover tool can also give additional protection to assist programming. Both Aptos and Sui are developed in Move and are a part of the growing MoveVM ecosystem.'
    },
    {
      value: '4. Why choose Sui?',
      child:
        'Sui is a permissionless, PoS-powered Layer 1 blockchain that has been designed in an attempt to provide instant settlement and high throughput as well as empower a multitude of next-gen latency-sensitive decentralized applications. Its asset-oriented architecture differentiates it from most of the mainstream layer1 blockchains, which could create possibilities for a variety of new Web3 features.'
    },
    {
      value: '5. Why choose Aptos?',
      child:
        `Aptos is a newborn layer1 blockchain that is full of potential and ambitions. It jumps out of the box that EVM ecosystem has created and pursuits the new gen of blockchain network with superior scalability, speed and robustness. We can see the increasing enthusiasm and demand for this eco. Cetus will be an important part of Aptos's evergrowing infrastructure and collectively create values for all ecosystem participants in this process.`,
      ccp: 3
    },
    {
      value: '6. What major benefits can Cetus bring to this ecosystem?',
      child:
        'Liquidity efficiency is the first thing that Cetus can redefine for this ecosystem, which is powered by its concentrated liquidity algorithm. Both liquidity providers and traders can benefit from this. By concentrating liquidity in an active price range, LPs can earn transaction fees more efficiently. Traders can enjoy low-slippage trading around the spot price during their swaps. In addition, developers can easily build their own products and tip into the most efficient liquidity source of Cetus by integrating with its openly-accessible smart contracts and SDKs.'
    },
    {
      value: '7. What incentives can liquidity providers get on Cetus?',
      child:
        'Through active participation in the protocol, liquidity providers can earn incentives in a few ways. First of all, the transaction fee is the primary earnings for most liquidity providers. One thing that is worth noting is that only a liquidity position that has an active price range (current price lies within its price range) is able to handle real-time transactions, thereby receiving transaction fees. Based on the basic fee earnings, some liquidity pools may provide additional liquidity mining rewards to give liquidity providers more incentives. The mining rewards for a liquidity provider depend on their position performance in generating transaction fees. Besides, there are also a couple of Loyalty Incentivization Programs on Cetus, like liquidity lockups and leaderboard campaigns, which allow those most active participants to earn extra rewards. '
    },
    {
      value: '8. Does Cetus have a token?',
      child:
        'Cetus adopted a double-token model, which includes CETUS and xCETUS (currently the TGE is yet to come). CETUS is the native protocol token of Cetus. Users can earn it through liquidity mining on Cetus. xCETUS is a non-transferable escrowed governance token, corresponding to staked CETUS. Users can get xCETUS by directly converting CETUS tokens, or through participating/contributing to the protocol upon requirements. CETUS can be converted 1:1 to xCETUS at any time. xCETUS can be redeemed back to CETUS through vesting and the conversion ratio depends on the selected vesting duration. '
    },
  ]
  const  faqText = (e:any)=>{
    if (faqVal && faqVal == e.child) {
      setFaqVal('')
      return
    }
    setFaqVal(e.child)
  }
  return (
    <div className="home_container">
      <HeaderBlock />
      <div className="banner">
        <div className="city-bg">
          <div className="all">
            <div className="city-bg-big city-one"></div>
            <div className="city-bg-big city-two"></div>
          </div>
          <div className="city-mask"></div>
          <div className="city-light"></div>
          <div className="cetus-bg"></div>
        </div>
        <div className="banner-content">
          <div className="center">
            <div className="public-chain">
              <span>Build on</span>
              <div className='build_text'>
                <TypedWithImage strings={stringsWithImages} />
              </div>
            </div>
            <div className="main-text">
              <p>Empower</p>
              <p className="liquidity">Liquidity.</p>
              <p>Built for DeFi.</p>
            </div>
            <p className="banner-desc h5-desc">Swap, earn, and build on the pioneer MOVE-based liquidity protocol.</p>
            <div className="main-btn">
              <a className="App" href="https://app.cetus.zone" target="_blank">
                <span className="Access">Access App</span>
                <span className="Coming">Coming soon</span>
              </a>
              <a className="Doc" href="https://cetus-1.gitbook.io/cetus-docs/" target="_blank">
                <div className="documentation">
                  Documentation
                </div>
              </a>
            </div>
            <p className="banner-desc pc-desc">Swap, earn, and build on the pioneer MOVE-based liquidity protocol.</p>
          </div>
        </div>
      </div>
      
      <div className="advantages">
        <div className="center">
          <div className="block_title">Advantages</div>
          <div className="advantages_content">
          <FadeInUpWhenVisible>
          <div className="permissionless advantages_item">
            <div className="animation-box">
              <div className="permissionless-one"></div>
              <div className="permissionless-two"></div>
              <div className="permissionless-three"></div>
              <div className="permissionless-four"></div>
              <div className="permissionless-five"></div>
            </div>
            <div className="text-box">
              <p>Permissionless</p>
              <span>All the major tools and functions on Cetus are basically built with a permissionless standard. It
                allows users or other applications to utilize its protocols for their own use cases at any time.</span>
            </div>
          </div>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
          <div className="programmable advantages_item">
            <div className="text-box">
              <p>Programmable</p>
              <span>Cetus builds a highly-customizable liquidity protocol based on CLMM. Through flexible composition of
                swap, range order and limit order, users can conduct almost all kinds of complex trading strategies that
                could be achieved on a CEX.</span>
            </div>
            <div className="animation-box">
              <div className="programmable-one"></div>
              <div className="programmable-two"></div>
              <div className="programmable-three"></div>
              <div className="programmable-four"></div>
              <div className="programmable-five"></div>
            </div>
          </div>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
          <div className="composability advantages_item">
            <div className="animation-box">
              <div className="composability-one"></div>
              <div className="composability-two"></div>
              <div className="composability-three"></div>
              <div className="composability-four"></div>
              <div className="composability-five"></div>
              <div className="composability-six"></div>
              <div className="composability-seven"></div>
              <div className="composability-eight"></div>
            </div>
            <div className="text-box">
              <p>Composability</p>
              <span>Cetus embraces the concept of 'Liquidity As A Service', so it puts emphasis on the ease of integration
                when building its products. Developers and applications can easily access liquidity on Cetus to build
                their own products.</span>
            </div>
          </div>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
          <div className="sustainability advantages_item">
            <div className="text-box">
              <p>Sustainability</p>
              <span>Cetus adopts a double-token model fueled by CETUS and xCETUS. Long term and dynamic incentivization
                sustained by protocol earnings is implemented to reward those active participants of the protocol.</span>
            </div>
            <div className="animation-box">
              <div className="sustainability-one"></div>
              <div className="sustainability-two"></div>
              <div className="sustainability-three"></div>
              <div className="sustainability-four"></div>
            </div>
          </div>
          </FadeInUpWhenVisible>
        </div>
        </div>
      </div>

      <div className="auditedBy">
        <div className="center">
          <FadeInUpWhenVisible>
          <div className="block_title">Audited by</div>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
            <div className="auditedBy_content">
              <FadScaleWhenHover>
                <div className="OtterSec-mask auditedBy_item">
                  <a className="OtterSec" href="https://osec.io/" target="_blank"></a>
                </div>
              </FadScaleWhenHover>
              <FadScaleWhenHover>
                <div className="MoveBit-mask auditedBy_item">
                  <a className="MoveBit" href="https://movebit.xyz/" target="_blank"></a>
                </div>
              </FadScaleWhenHover>
            </div>
          </FadeInUpWhenVisible>
        </div>
      </div>

      <div className="investors">
        <div className="center">
        <FadeInUpWhenVisible>
          <div className="block_title">
            Backers
          </div>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
            <div className="content">
              <div className="row">
              <FadeInUpWhenVisible>
                <img src={img_OKX} alt="" />
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
                <img src={img_KUCOIN} alt="" />
              </FadeInUpWhenVisible>
              </div>
              <div className="row">
              <FadeInUpWhenVisible>
                <img src={img_sui} alt=""/>
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
                <img src={img_Comma3} alt=""/>
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
                <img src={img_NGC} alt=""/>
              </FadeInUpWhenVisible>
              </div>
              <div className="row">
              <FadeInUpWhenVisible>
                <img src={img_animca} alt=""/>
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
                <img src={img_IDG} alt=""/>
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
                <img src={img_Leland} alt=""/>
              </FadeInUpWhenVisible>
              </div>
              <div className="row">
              <FadeInUpWhenVisible>
                <img src={img_AC} alt=""/>
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
                <img src={img_Coin98} alt=""/>
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
                <img src={img_Gate} alt=""/>
              </FadeInUpWhenVisible>
              </div>
            </div>
            <div className="h5-content">
              <img src={img_OKX_h5} alt="" />
              <img src={img_KUCOIN_h5} alt="" />
              <img src={img_SUI_h5} alt="" />
              <img src={img_Comma_h5} alt="" />
              <img src={img_NGC_h5} alt="" />
              <img src={img_animca_h5} alt="" />
              <img src={img_IDG_h5} alt="" />
              <img src={img_Leland_h5} alt="" />
              <img src={img_AC_h5} alt="" />
              <img src={img_Coin98_h5} alt="" />
              <img src={img_Gate_h5} alt="" />
            </div>
          </FadeInUpWhenVisible>
        </div>
      </div>

      <div className="aquarium">
        <FadeInUpWhenVisible>
          <div className="center">
            <FadeInUpWhenVisible delay='0.2'>
              <div className="content">
                <div className="left">
                  <img src={img_cetusaquarium_left} alt="" />
                </div>
                <div className="right">
                  <div className="power">
                    <div className="power-text">POWERED BY </div>
                    <img src={icon_sui} alt="" />
                  </div>
                  <p className="block_title">
                    Cetus Aquarium
                  </p>
                  <div className="desc">Incubator Program</div>
                  <div className="text">Powered by Sui Foundation, Cetus Aquarium is a non-profit Incubator Program dedicated to nurturing high-potential projects within the Sui ecosystem. It offers comprehensive support, encompassing technical, operational, and business advice, and accelerated grant funding plus a series of key resources that may help a project to thrive.</div>
                  <div className="main-btn">
                  <a className="App" href="https://4bx69zjogri.typeform.com/to/MTZ4hMFN" target="_blank">
                    <span className="Access">Apply Now</span>
                  </a>
                </div>
              </div>
              </div>
            </FadeInUpWhenVisible>
          </div>
        </FadeInUpWhenVisible>
      </div>

      <div className="focus-on-move">
        <div className="center">
        <div className="focus-main">
        <FadeInUpWhenVisible>
          <p className="block_title">
            Focus on Move
          </p>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
          <div className="focus-item focus-sui">
            <div className="content">
              <div className="content-tit">
                <span>Sui</span>
                <div className="right">
                  <a href="https://sui.io" className="webiste" target="_blank"></a>
                  <a href="https://twitter.com/SuiNetwork" className="twitter" target="_blank"></a>
                  <a href="https://github.com/MystenLabs" className="github" target="_blank"></a>
                </div>
              </div>
              <div className="content-detail">
                Sui is a permissionless, PoS-powered Layer 1 blockchain that has been designed in an attempt to provide
                instant settlement and high throughput as well as empower a multitude of next-gen latency-sensitive
                decentralized applications.
              </div>
            </div>
            <img className="public" src={icon_sui_bg} alt="" />
          </div>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
          <div className="focus-item focus-aptos">
            <img className="public" src={icon_aptos_bg} alt="" />
            <div className="content">
              <div className="content-tit">
                <span>Aptos</span>
                <div className="right">
                  <a href="https://aptosfoundation.org" className="webiste" target="_blank"></a>
                  <a href="https://twitter.com/aptos_network" className="twitter" target="_blank"></a>
                  <a href="https://github.com/aptos-foundation" className="github" target="_blank"></a>
                </div>
              </div>
              <div className="content-detail">
                Aptos is a layer-1 proof-of-stake blockchain developed by Aptos Labs. Aptos is noted for its high
                transaction throughput in terms of transactions per second (TPS) and its time to finality (TTF), enabled
                by its modular architecture which has decoupled transaction dissemination and consensus.
              </div>
            </div>
          </div>
          </FadeInUpWhenVisible>
        </div>
        </div>
      </div>

      <div className="powered-by">
        <div className="center">
          <FadeInUpWhenVisible>
            <div className="left">
              <div className="top">Powered by</div>
              <img src={full_logo} alt="" />
            </div>
          </FadeInUpWhenVisible>
          <FadeInUpWhenVisible>
            <div className="right">
              <div className="text">
                Cetus utilizes TradingView technology to display price on charts. 
                TradingView is a charting platform for a global community of traders 
                and investors. Supported by robust technologies across browser, 
                desktop and mobile apps, the platform provides unparalleled access 
                to real-time data such as&nbsp;
                <a href="https://www.tradingview.com/symbols/BTCUSD" target="_blank">BTC USD Chart</a>,
                the latest news, 
                financial reports, Stock screener and Economic calendar.
              </div>
            </div>
          </FadeInUpWhenVisible>
        </div>
      </div>

      <div className="faq">
        <div className="center">
          <p className="block_title">
            FAQ
          </p>
          {
            faqForm.map((item)=>{
              return(
                <div key={item.value} className={faqVal == item.child ? 'botBto chosen' : 'botBto'}>
                  <FadeInUpWhenVisible>
                  <div className="top-botBto"  onClick={()=>faqText(item)}>
                    <span className={item.ccp ? 'cftcy' : ''}>{ item.value }</span>
                    <p></p>
                    <FadScaleWhenHover>
                      <div className={faqVal == item.child ? 'change' : ''}></div>
                    </FadScaleWhenHover>
                  </div>
                  
                  {faqVal == item.child&&
                  <FadeInWhenVisible>
                  <div className="bot-botBto">
                    <span>{ item.child }</span>
                  </div>
                  </FadeInWhenVisible>
                  }
                </FadeInUpWhenVisible> 
                </div>
              )
            })
          }
        </div>
        <div className="footer-bg-box"></div>
      </div>

      <FadeInUpWhenVisible>
        <FooterBlock />
      </FadeInUpWhenVisible>
    </div>
  );
}

export default App;
