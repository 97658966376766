import '../../assets/css/footer.less';
import img_logo from '../../assets/image/logo@2x.png';
import FadScaleWhenHover from '../../components/animation/FadScaleWhenHover';
export default function FooterBlock() {
  return (
    <div className="footer">
    <div className="center">
    <div className="footer">
    <div className="footer-container">
      <div className="left">
        <a href="/">
          <img src={img_logo} alt="" />
        </a>
      </div>
      <div className="right">
        <div className="left-detail">
          <p className="top-foot">Resources</p>
          <FadScaleWhenHover isBigger>
          <a href="https://cetus-1.gitbook.io/cetus-docs/" target="_blank">Documentation</a>
          </FadScaleWhenHover>
          <FadScaleWhenHover isBigger>
          <a href="/terms">Terms of use</a>
          </FadScaleWhenHover>
          <FadScaleWhenHover isBigger>
          <a href="/disclaimer">Disclaimer</a>
          </FadScaleWhenHover>
        </div>
        <div className="social-detail">
          <p className="top-foot">Social Media</p>
          <FadScaleWhenHover isBigger>
          <a href="https://twitter.com/cetusprotocol" target="_blank">Twitter</a>
          </FadScaleWhenHover>
          <FadScaleWhenHover isBigger>
          <a href="https://discord.gg/cetusprotocol" target="_blank">Discord</a>
          </FadScaleWhenHover>
          <FadScaleWhenHover isBigger>
          <a href="https://t.me/cetusprotocol" target="_blank">TG Channel</a>
          </FadScaleWhenHover>
          <FadScaleWhenHover isBigger>
          <a href="https://medium.com/@CetusProtocol" target="_blank">Medium</a>
          </FadScaleWhenHover>
        </div>
        <div className="contact-detail">
          <p className="top-foot">Contact</p>
          <a href="/">hello@cetus.zone</a>
        </div>
      </div>
    </div>
    <div className="address">
      © 2024 Cetus. All rights reserved.
    </div>
  </div>
    </div>
    </div>
  );
}
