import { motion } from 'framer-motion';

const FadScaleWhenHover = ({ children,isBigger=false }:any) => {
  return (
    <motion.div
        whileHover={{ scale: isBigger?1.05:0.95 }}
        whileTap={{ scale: 0.98 }}
    >{children}
    </motion.div>
  );
};

export default FadScaleWhenHover;